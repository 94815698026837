import React from 'react';
import styles from  './table-legend.module.scss'; // import CSS file with animation styles
import ProgressIcon from "../progressIcon/progress-icon";

const  TableNotes = ({children}) => {


    return (
        <div className={`mt-5 ${styles.tableTextWrapper} ${styles.footerNotes}`}>
            <h5>Notes</h5>
            {children}
            <h5>Source: New World Wealth</h5>
        </div>
    );
}

export default TableNotes;
