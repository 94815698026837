import React, { useState, Fragment } from 'react';
import styles from './cities-to-watch.module.scss';
import { dataConvertOverview } from '../../utils/overall-convert';
// import { centiOverview } from '../../data/data-home-to-centi-overview';
import {dataCitiesToWatch} from "../../data/data-cities-to-watch";
import { formatNumberWithDot } from '../../utils/utils';
import CountryFlag from '../common/countryFlag/country-flag';
import SelectDropdown from '../common/selectDropdown/select-dropdown';
import IconSort from '../common/icon/icon-sort';
import ReverseIconSort from '../common/icon/reverse-icon-sort';
import ProgressIcon from "../common/progressIcon/progress-icon";
import TableLegend from "../common/tableLegend/table-legend";
import TableNotes from "../common/tableLegend/table-notes";

const options = [
    { label: "Centi-Millionaires (USD 100m+)", value: "centiMillionaires" },
    { label: "Centi-Millionaire Growth Forecast % (2024 to 2040)", value: "centiGrowth" },
];

const CitiesToWatch = () => {

    const [sortColumn, setSortColumn] = useState("centiMillionaires");
    const [countryCount, setCountryCount] = useState(1);
    const [centiMilCount, setCentiMilCount] = useState(1);
    const [centiGrowthCount, setCentiGrowthCount] = useState(1);
    const [numOfCitiesShow, setNumOfCitiesShow] = useState(10);
    const [hideList, setHideList] = useState(true);


    const dataCentiOverview = () => {
        return dataConvertOverview(dataCitiesToWatch);
    }


    const handleOptionSelect = (option) => {
        // setSelectedOption(option);
        setSortColumn(option.value);
        // setDisplay(-1);
    };

    const handleSort = (columnName) => {
        switch (columnName) {
            case 'country':
                (countryCount === 1) ? setCountryCount(-1) : setCountryCount(1);
                break;
            case 'centiMillionaires':
                (centiMilCount === 1) ? setCentiMilCount(-1) : setCentiMilCount(1);
                break;
            case 'centiGrowth':
                (centiGrowthCount === 1) ? setCentiGrowthCount(-1) : setCentiGrowthCount(1);
                break;
            default:
                break;
        }

        setSortColumn(columnName);
        // setDisplay(-1);

    };

    const reverseSortNum = (columnName, count) => {
        if (count === 1) {
            return dataCentiOverview().sort((a, b) => b[columnName] - a[columnName]);
        }
        return dataCentiOverview().sort((a, b) => a[columnName] - b[columnName]);
    }

    const handleSortingOptions = (columnName) => {
        switch (columnName) {
            case 'country':
                return (countryCount === 1) ?
                    dataCentiOverview().sort((a, b) => b[columnName].localeCompare(a[columnName]))
                    : dataCentiOverview().sort((a, b) => a[columnName].localeCompare(b[columnName]));
            case 'centiMillionaires':
                return reverseSortNum(columnName, centiMilCount);
            case 'centiGrowth':
                return reverseSortNum(columnName, centiGrowthCount);
            default:
                break;
        }
    }


    const sortedData = sortColumn
        ? handleSortingOptions(sortColumn)
        : dataCentiOverview();

    // const handleMobileRowClick = (index) => {
    //   setDisplay(index);
    // }

    const handleShowHideList = () => {
        setHideList(!hideList);
        setNumOfCitiesShow(numOfCitiesShow === 10 ? `${sortedData.length}` : 10);
    };


    return (
        <>
            <div className={`d-md-none ${styles.sortMobileWrapper}`}>
                <div className={styles.sortTitle}>Sort by</div>
                <SelectDropdown options={options} onSelect={handleOptionSelect} />
            </div>
            <table className={`chartTable ${styles.overviewChartTable}`} >
                <thead>
                    <tr>
                        <th className={styles.cityColumn} ><span className={styles.columnText}>City<span className={styles.showTextOnMobile}> / Country</span></span></th>
                        <th
                            className={`text-left ${styles.cellHideMobile} ${styles.countryColumn} ${styles.sortableColumn} ${sortColumn === 'country' ? styles.selectedColumn : ''}`}
                            onClick={() => handleSort("country")}
                        >
                            <div className={`${styles.sortColumnTitle} ${styles.reverseSortColumnTitle}`}>
                                <span className={styles.sortColumnTitleText}>Country</span>
                                {(countryCount === 1) ? <IconSort /> : <ReverseIconSort />}
                            </div>
                        </th>

                        <th
                            className={
                                `text-right 
                                  ${styles.cellHideMobile}
                                  ${styles.sortableColumn} 
                                  ${styles.dataColumn}
                                  ${sortColumn === 'centiMillionaires' ? styles.selectedColumn : ''}
                                  ${sortColumn === 'centiMillionaires' ? styles.displayColumnTitleDiv : ''}
                  `}
                            onClick={() => handleSort("centiMillionaires")}
                        >
                            <div className={styles.sortColumnTitle}>
                                <span className={styles.sortColumnTitleText}>Centi-Millionaires<br />(USD 100m+)</span>
                                {(centiMilCount === 1) ? <IconSort /> : <ReverseIconSort />}
                            </div>
                        </th>

                        <th
                            className={
                                ` 
                                  ${styles.cellHideMobile}
                                  ${styles.sortableColumn} 
                                  ${styles.progressBarColumn}
                                  ${sortColumn === 'centiGrowth' ? styles.selectedColumn : ''}
                                  ${sortColumn === 'centiGrowth' ? styles.displayColumnTitleDiv : ''}
                  `}
                            onClick={() => handleSort("centiGrowth")}
                        >
                            <div className={`${styles.sortColumnTitle} ${styles.reverseSortColumnTitle}`}>
                                <span className={`${styles.sortColumnTitleText}`}>Centi-Millionaire Growth Forecast %<br />(2024 to 2040)</span>
                                {(centiGrowthCount === 1) ? <IconSort /> : <ReverseIconSort />}
                            </div>
                        </th>

                    </tr>
                </thead>
                <tbody>
                {sortedData.map((item, index) => (
                    <Fragment key={index}>
                        <tr className={`${styles.contentRow} ${styles.openedRow}`}>
                            {/* <tr onClick={() => handleMobileRowClick(index)} className={`${styles.contentRow} ${index === display ? styles.openedRow : ''}`}> */}
                            <td>{item.city}</td>
                            <td className={`text-left 
                                ${styles.cellHideMobile} 
                                ${styles.sortableColumn} 
                                ${sortColumn === 'country' ? styles.selectedColumn : ''}
                              `}>
                                <CountryFlag country={item.country} />
                            </td>

                            <td rowSpan={2} className={`text-right 
                                            ${styles.hiddenTableRow} 
                                            ${styles.sortableColumn} 
                                            ${styles.combinateRowCells} 
                                            ${sortColumn ? styles.selectedColumn : ''} 
                                          
                                          `}>
                                {sortColumn === "centiGrowth" ?
                                    <ProgressIcon count={item.centiGrowth} />
                                    : formatNumberWithDot(item[sortColumn])
                                }
                            </td>

                            <td className={`text-right 
                                ${styles.cellHideMobile} 
                                ${styles.sortableColumn} 
                                ${styles.cellOnMobile} 
                                ${sortColumn === 'centiMillionaires' ? styles.selectedColumn : ''}
                              `}>
                                {formatNumberWithDot(item.centiMillionaires)}
                            </td>

                            <td className={`text-right 
                                ${styles.cellHideMobile} 
                                ${styles.sortableColumn} 
                                ${styles.cellOnMobile} 
                              
                                ${sortColumn === 'centiGrowth' ? styles.selectedColumn : ''}
                              `}>
                                <ProgressIcon count={item.centiGrowth} />
                            </td>
                        </tr>
                        {/* {index === display && ( */}
                        <tr className={`${styles.hiddenTableRow}`}>
                            <td className={`${styles.countryMobileDiv}`}>
                                <CountryFlag country={item.country} />
                            </td>
                        </tr>
                        {/* )}  */}

                    </Fragment>
                ))}
                </tbody>
            </table>

            {/*<div className={styles.showButton} style={{ margin: "20px auto" }}>*/}
            {/*    <button className={styles.collapseButton} onClick={handleShowHideList}>*/}
            {/*        {hideList ? `Show All ${sortedData.length} Cities` : "Collapse list"}*/}
            {/*    </button>*/}
            {/*</div>*/}


            <TableLegend />
            <TableNotes>
                <ul>
                    <li>'Centi-millionaires' refers to individuals with liquid investable wealth of USD 100 million or more.</li>
                    <li>Only includes individuals living in each city (residents).</li>
                    <li>Figures for December 2023.</li>
                </ul>
            </TableNotes>
            {/*<div className="footer-note mt-5">*/}
            {/*    <ul>*/}
            {/*        <li>Figures for June 2023.</li>*/}
            {/*        <li>Only includes individuals living in each city (residents).</li>*/}
            {/*        <li>'Centi-millionaires' refers to individuals with investable wealth of USD 100 million or more.</li>*/}
            {/*    </ul>*/}
            {/*    <p>Source: New World Wealth</p>*/}
            {/*</div>*/}
        </>
    )
}

export default CitiesToWatch;