import React from 'react';
import styles from  './table-legend.module.scss'; // import CSS file with animation styles
import ProgressIcon from "../progressIcon/progress-icon";

const  TableLegend = () => {


    return (
       <div className={`mt-5 ${styles.tableTextWrapper}`}>
           <h5>Legend: Centi-Millionaire Growth Forecasts 2024–2040</h5>
           <div className={styles.legendList}>
               <div className={styles.each}>
                   <div className={styles.iconWrapper}>
                        <ProgressIcon count="4"/>
                   </div>
                   <span className={styles.legendText}>Very high wealth growth: <span className={styles.legendNumber}>&gt;150%</span></span>
               </div>
               <div className={styles.each}>
                   <div className={styles.iconWrapper}>
                        <ProgressIcon count="3"/>
                   </div>
                   <span className={styles.legendText}>High wealth growth: <span className={styles.legendNumber}>100% to 150%</span></span>
               </div>
               <div className={styles.each}>
                   <div className={styles.iconWrapper}>
                        <ProgressIcon count="2"/>
                   </div>
                   <span className={styles.legendText}>Moderate wealth growth: <span className={styles.legendNumber}>50% to 100%</span></span>
               </div>
               <div className={styles.each}>
                   <div className={styles.iconWrapper}>
                        <ProgressIcon count="1"/>
                   </div>
                   <span className={styles.legendText}>Low wealth growth: <span
                       className={styles.legendNumber}>&lt;50%</span></span>
               </div>
           </div>
       </div>
    );
}

export default TableLegend;
