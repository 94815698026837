import React, { useState, Fragment } from 'react';
import styles from './seasonal-cities.module.scss';
import { dataConvertOverview } from '../../utils/overall-convert';
import { seasonalCitiesOverview } from "../../data/data-seasonal-cities";
import { formatNumberWithDot } from '../../utils/utils';
import CountryFlag from '../common/countryFlag/country-flag';
import SelectDropdown from '../common/selectDropdown/select-dropdown';
import IconSort from '../common/icon/icon-sort';
import ReverseIconSort from '../common/icon/reverse-icon-sort';
import ProgressIcon from "../common/progressIcon/progress-icon";
import TableLegend from "../common/tableLegend/table-legend";
import TableNotes from "../common/tableLegend/table-notes";

const options = [
  { label: "Residents + Second Home Owners", value: "secondhome" },
  { label: "Residents", value: "residents" },
  { label: "Centi-Millionaire Growth Forecast % (2024 to 2040)", value: "centiGrowth" },
];

const SeasonalCities = () => {

  const [sortColumn, setSortColumn] = useState("secondhome");
  // const [display, setDisplay] = useState();
  const [countryCount, setCountryCount] = useState(1);
  const [secondhomeCount, setSecondhomeCount] = useState(1);
  const [residentsCount, setResidentsCount] = useState(1);
  const [centiGrowthCount, setCentiGrowthCount] = useState(1);
  const [numOfCitiesShow, setNumOfCitiesShow] = useState(10);
  const [hideList, setHideList] = useState(true);

  const handleOptionSelect = (option) => {
    // setSelectedOption(option);
    setSortColumn(option.value);
    // setDisplay(-1);
  };

  const dataSeasonalOverview = () => { 
    return dataConvertOverview(seasonalCitiesOverview);
  }
  
  const handleSort = (columnName) => {
    switch (columnName) {
      case 'country':
        (countryCount === 1) ? setCountryCount(-1) : setCountryCount(1);
        break;
      case 'secondhome':
        (secondhomeCount === 1) ? setSecondhomeCount(-1) : setSecondhomeCount(1);
        break;
      case 'residents':
        (residentsCount === 1) ? setResidentsCount(-1) : setResidentsCount(1);
        break;
      case 'centiGrowth':
        (centiGrowthCount === 1) ? setCentiGrowthCount(-1) : setCentiGrowthCount(1);
        break;
      default:
        break;
    }
    
    setSortColumn(columnName);
    // setDisplay(-1);
  };

  const reverseSortNum = (columnName, count) => {
    if (count === 1) {
      return dataSeasonalOverview().sort((a, b) => b[columnName] - a[columnName]);
    }
    return dataSeasonalOverview().sort((a, b) => a[columnName] - b[columnName]);
  }
  

  const handleSortingOptions = (columnName) => {
    switch (columnName) {
      case 'country':
        return (countryCount === 1) ?
         dataSeasonalOverview().sort((a, b) => b[columnName].localeCompare(a[columnName]))
        : dataSeasonalOverview().sort((a, b) => a[columnName].localeCompare(b[columnName]));
      case 'secondhome':
        return reverseSortNum(columnName, secondhomeCount);
      case 'residents':
        return reverseSortNum(columnName, residentsCount);
      case 'centiGrowth':
        return reverseSortNum(columnName, centiGrowthCount);
      default:
        break;
    }

  }

  const sortedData = sortColumn
    ? handleSortingOptions(sortColumn)
    : dataSeasonalOverview();

  // const handleMobileRowClick = (index) => {
  //   setDisplay(index);
  // }

  const handleShowHideList = () => {
    setHideList(!hideList);
    setNumOfCitiesShow(numOfCitiesShow === 10 ? `${sortedData.length}` : 10);
  };


  return (
    <>
      <div className={`d-md-none ${styles.sortMobileWrapper}`}>
        <div className={styles.sortTitle}>Sort by</div>
        <SelectDropdown options={options} onSelect={handleOptionSelect} />
      </div>
      <table className={`chartTable ${styles.overviewChartTable}`}>
        <thead>
        <tr>
          <td style={{'border': 'None'}}></td>
          <td className={styles.cellHideMobile} style={{'border': 'None'}}></td>
          <th colSpan="2" className={`text-center ${styles.topTableHeader} ${styles.cellHideMobile} ${sortColumn !== 'centiGrowth' ? styles.selectedColumn : ''} ${sortColumn !== 'centiGrowth' ? styles.displayColumnTitleDiv : ''}`}>
            <span>Centi-Millionaires (USD 100m+)</span>
          </th>
          <td className={`${styles.topTableHeader} ${styles.cellHideMobile} ${sortColumn === 'centiGrowth' ? styles.selectedColumn : ''} ${sortColumn === 'centiGrowth' ? styles.displayColumnTitleDiv : ''}`} style={{'border': 'None'}}></td>
          {/* <td className={styles.cellHideMobile} style={{ 'border': 'None' }}></td> */}
        </tr>

        <tr>
          <th className={styles.cityColumn} width="25%"><span className={styles.columnText}>City<span className={styles.showTextOnMobile}> / Country</span></span>
          </th>
          <th
              className={`text-left ${styles.cellHideMobile}  ${styles.sortableColumn} ${sortColumn === 'country' ? styles.selectedColumn : ''}`}
              width="25%"
              onClick={() => handleSort("country")}
          >
            <div className={`${styles.sortColumnTitle} ${styles.reverseSortColumnTitle}`}>
              <span className={styles.sortColumnTitleText}>Country</span>
              {(countryCount === 1) ? <IconSort/> : <ReverseIconSort/>}
            </div>
          </th>

          <th
              className={
                `text-right 
                              ${styles.cellHideMobile}  
                              ${styles.sortableColumn} 
                              ${sortColumn === 'secondhome' ? styles.selectedColumn : ''}
                              ${sortColumn === 'secondhome' ? styles.displayColumnTitleDiv : ''}
              `}
              onClick={() => handleSort("secondhome")}
          >
            <div className={styles.sortColumnTitle}>
              <span className={styles.sortColumnTitleText}>Residents + Second<br/>Home Owners</span>
              {(secondhomeCount === 1) ? <IconSort/> : <ReverseIconSort/>}
            </div>
          </th>
          <th
              className={
                `text-right 
                              ${styles.cellHideMobile}  
                              ${styles.sortableColumn} 
                              ${sortColumn === 'residents' ? styles.selectedColumn : ''}
                              ${sortColumn === 'residents' ? styles.displayColumnTitleDiv : ''}
              `}
              onClick={() => handleSort("residents")}
          >
            <div className={styles.sortColumnTitle}>
              <span className={styles.sortColumnTitleText}>Residents</span>
              {(residentsCount === 1) ? <IconSort/> : <ReverseIconSort/>}
            </div>
          </th>
          <th
              className={
                ` 
                              ${styles.cellHideMobile}
                              ${styles.sortableColumn} 
                              ${styles.progressBarColumn}
                              ${sortColumn === 'centiGrowth' ? styles.selectedColumn : ''}
                              ${sortColumn === 'centiGrowth' ? styles.displayColumnTitleDiv : ''}
              `}
              onClick={() => handleSort("centiGrowth")}
          >
            <div className={`${styles.sortColumnTitle} ${styles.reverseSortColumnTitle}`}>
              <span
                  className={`${styles.sortColumnTitleText}`}>Centi-Millionaire Growth Forecast %<br />(2024 to 2040)</span>
              {(centiGrowthCount === 1) ? <IconSort/> : <ReverseIconSort/>}
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        {sortedData.map((item, index) => (
            <Fragment key={index}>
              <tr className={`${styles.contentRow} ${styles.openedRow}`}>
                <td>{item.city}</td>
                <td className={`text-left 
                                ${styles.cellHideMobile} 
                                ${styles.sortableColumn} 
                                ${sortColumn === 'country' ? styles.selectedColumn : ''}
                              `}>
                  <CountryFlag country={item.country}/>
                </td>

                <td rowSpan={2} className={`text-right 
                                            ${styles.hiddenTableRow} 
                                            ${styles.sortableColumn} 
                                            ${styles.combinateRowCells} 
                                            ${sortColumn ? styles.selectedColumn : ''} 
                                            ${sortColumn === 'centiGrowth' ? styles.percentColumn : ''}
                                          `}>
                  {sortColumn === 'centiGrowth' ?
                      <ProgressIcon count={item.centiGrowth} />
                      : formatNumberWithDot(item[sortColumn])
                  }
                  {sortColumn === 'secondhome' ? '+' : ''}

                </td>

                <td className={`text-right 
                                ${styles.cellHideMobile} 
                                ${styles.sortableColumn} 
                                ${styles.cellOnMobile} 
                                ${sortColumn === 'secondhome' ? styles.selectedColumn : ''}
                              `}>
                  {formatNumberWithDot(item.secondhome) + "+"}
                </td>
                <td className={`text-right 
                                ${styles.cellHideMobile} 
                                ${styles.sortableColumn} 
                                ${styles.cellOnMobile} 
                                ${sortColumn === 'residents' ? styles.selectedColumn : ''}
                              `}>
                  {formatNumberWithDot(item.residents)}
                </td>
                <td className={`text-right 
                                ${styles.cellHideMobile} 
                                ${styles.sortableColumn} 
                                ${styles.cellOnMobile} 
     
                                ${sortColumn === 'centiGrowth' ? styles.selectedColumn : ''}
                              `}>
                  <ProgressIcon count={item.centiGrowth} />
                </td>
              </tr>
              <tr className={`${styles.hiddenTableRow}`}>
                <td className={`${styles.countryMobileDiv}`}>
                  <CountryFlag country={item.country}/>
                </td>
              </tr>

            </Fragment>
        ))}

        <tr>
          <td className={`${styles.bufferBeforeTotal} `}
              style={{borderBottom: 0}}
          ></td>
          <td className={`${styles.cellHideMobile} 
                            ${styles.bufferBeforeTotal}`} 
                style={{ borderBottom: 0 }}
            ></td>
            <td className={`${styles.cellHideMobile} 
                            ${styles.bufferBeforeTotal}`} 
                style={{ borderBottom: 0 }}
              ></td>
            <td className={`${styles.cellHideMobile} 
                            ${styles.bufferBeforeTotal}`} 
                style={{ borderBottom: 0 }}
              ></td>

          </tr>
          
          
          {/*<tr>*/}
          {/*  <td className={`${styles.bufferBeforeTotal}`}*/}
          {/*      style={{ borderBottom: 0 }}*/}
          {/*  ></td>*/}
          {/*  <td className={`${styles.cellHideMobile} */}
          {/*                  ${styles.bufferBeforeTotal}`} */}
          {/*      style={{ borderBottom: 0 }}*/}
          {/*  ></td>*/}
          {/*  <td className={`${styles.bufferBeforeTotal}`} */}
          {/*      style={{ borderBottom: 0 }}*/}
          {/*    >*/}
          {/*      <div className={styles.showButton} style={{ paddingTop: "20px" }}>*/}
          {/*        <button className={styles.collapseButton} onClick={handleShowHideList}>*/}
          {/*          {hideList ? `Show All ${sortedData.length} Cities` : "Collapse list"}*/}
          {/*        </button>*/}
          {/*      </div>*/}
          {/*    </td>*/}
          {/*  <td className={`${styles.cellHideMobile} */}
          {/*                  ${styles.bufferBeforeTotal}`} */}
          {/*      style={{ borderBottom: 0 }}*/}
          {/*    ></td>*/}
          {/*  <td className={`${styles.cellHideMobile} */}
          {/*                  ${styles.bufferBeforeTotal}`} */}
          {/*      style={{ borderBottom: 0 }}*/}
          {/*  ></td>*/}
          {/*</tr>*/}
          
        
        </tbody>
      </table>

      <TableLegend />
      <TableNotes>
        <ul>
          <li>'Centi-millionaires' refers to individuals with liquid investable wealth of USD 100 million or more.</li>
          <li>Figures for December 2023.</li>
        </ul>
      </TableNotes>

    </>
  )
}

export default SeasonalCities;