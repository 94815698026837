const expensiveCitiesOverview = [
    {
        "city": "Monaco",
        "country": "Monaco",
        "price": "36200"
    },
    {
        "city": "New York City",
        "country": "USA",
        "price": "28000"
    },
    {
        "city": "Hong Kong",
        "country": "Hong Kong (SAR China)",
        "price": "27500"
    },
    {
        "city": "Saint Jean Cap Ferrat",
        "country": "France",
        "price": "24800"
    },
    {
        "city": "Sydney",
        "country": "Australia",
        "price": "22400"
    },
    {
        "city": "London",
        "country": "UK",
        "price": "20300"
    },
    {
        "city": "Paris",
        "country": "France",
        "price": "18500"
    },
    {
        "city": "Los Angeles",
        "country": "USA",
        "price": "18000"
    },
    {
        "city": "Palm Beach",
        "country": "USA",
        "price": "17800"
    },
    {
        "city": "Miami Beach",
        "country": "USA",
        "price": "17600"
    },
    {
        "city": "Singapore",
        "country": "Singapore",
        "price": "16400"
    },
    {
        "city": "The Bay Area",
        "country": "USA",
        "price": "15600"
    },
    {
        "city": "Geneva",
        "country": "Switzerland",
        "price": "15200"
    },
    {
        "city": "Nice",
        "country": "France",
        "price": "15000"
    },
    {
        "city": "Portofino",
        "country": "Italy",
        "price": "15000"
    },
    {
        "city": "Cannes",
        "country": "France",
        "price": "14800"
    },
    {
        "city": "Tokyo",
        "country": "Japan",
        "price": "14600"
    },
    {
        "city": "Lugano",
        "country": "Switzerland",
        "price": "14600"
    },
    {
        "city": "Saint Tropez",
        "country": "France",
        "price": "14500"
    },
    {
        "city": "Antibes",
        "country": "France",
        "price": "14500"
    }
]


export {expensiveCitiesOverview};