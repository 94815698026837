const dataCitiesToWatch = [
    {
        "city": "Riyadh",
        "country": "Saudi Arabia",
        "centiMillionaires": 67,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Manchester and Trafford",
        "country": "UK",
        "centiMillionaires": 67,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Philadelphia",
        "country": "USA",
        "centiMillionaires": 66,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Atlanta",
        "country": "USA",
        "centiMillionaires": 65,
        "centiGrowthText": "LOW",
        "centiGrowth": 1
    },
    {
        "city": "Perth",
        "country": "Australia",
        "centiMillionaires": 64,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Stockholm",
        "country": "Sweden",
        "centiMillionaires": 64,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Scottsdale and Paradise Valley",
        "country": "USA",
        "centiMillionaires": 63,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Istanbul",
        "country": "Türkiye",
        "centiMillionaires": 61,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Montecito and Santa Barbara",
        "country": "USA",
        "centiMillionaires": 60,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "St. Petersburg",
        "country": "Russian Federation",
        "centiMillionaires": 58,
        "centiGrowthText": "LOW",
        "centiGrowth": 1
    },
    {
        "city": "Mexico City",
        "country": "Mexico",
        "centiMillionaires": 53,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Oslo",
        "country": "Norway",
        "centiMillionaires": 52,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Kyoto",
        "country": "Japan",
        "centiMillionaires": 50,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Doha",
        "country": "Qatar",
        "centiMillionaires": 48,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Berlin",
        "country": "Germany",
        "centiMillionaires": 47,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "São Paulo",
        "country": "Brazil",
        "centiMillionaires": 45,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Dublin",
        "country": "Ireland",
        "centiMillionaires": 43,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Auckland",
        "country": "New Zealand",
        "centiMillionaires": 42,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Athens",
        "country": "Greece",
        "centiMillionaires": 42,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Bengaluru",
        "country": "India",
        "centiMillionaires": 42,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Hamburg",
        "country": "Germany",
        "centiMillionaires": 41,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Copenhagen",
        "country": "Denmark",
        "centiMillionaires": 40,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Bangkok",
        "country": "Thailand",
        "centiMillionaires": 40,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Brisbane",
        "country": "Australia",
        "centiMillionaires": 40,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Zug (canton)",
        "country": "Switzerland",
        "centiMillionaires": 40,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Brussels",
        "country": "Belgium",
        "centiMillionaires": 38,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Lisbon",
        "country": "Portugal",
        "centiMillionaires": 37,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Rio",
        "country": "Brazil",
        "centiMillionaires": 36,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Lugano",
        "country": "Switzerland",
        "centiMillionaires": 35,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Helsinki",
        "country": "Finland",
        "centiMillionaires": 35,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Denver",
        "country": "USA",
        "centiMillionaires": 35,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Antwerp",
        "country": "Belgium",
        "centiMillionaires": 34,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Edinburgh",
        "country": "UK",
        "centiMillionaires": 34,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Kuala Lumpur",
        "country": "Malaysia",
        "centiMillionaires": 34,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Jakarta",
        "country": "Indonesia",
        "centiMillionaires": 33,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Warsaw",
        "country": "Poland",
        "centiMillionaires": 33,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Jeddah",
        "country": "Saudi Arabia",
        "centiMillionaires": 32,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Tampa and Sarasota",
        "country": "USA",
        "centiMillionaires": 30,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Barcelona",
        "country": "Spain",
        "centiMillionaires": 30,
        "centiGrowthText": "LOW",
        "centiGrowth": 1
    },
    {
        "city": "Cairo",
        "country": "Egypt",
        "centiMillionaires": 30,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Boca Raton",
        "country": "USA",
        "centiMillionaires": 30,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Florence",
        "country": "Italy",
        "centiMillionaires": 30,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Jerusalem",
        "country": "Israel",
        "centiMillionaires": 28,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Cape Town",
        "country": "South Africa",
        "centiMillionaires": 28,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Cannes",
        "country": "France",
        "centiMillionaires": 28,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Prague",
        "country": "Czech Republic",
        "centiMillionaires": 28,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Naples",
        "country": "USA",
        "centiMillionaires": 27,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Montreal",
        "country": "Canada",
        "centiMillionaires": 26,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Budapest",
        "country": "Hungary",
        "centiMillionaires": 25,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Johannesburg",
        "country": "South Africa",
        "centiMillionaires": 25,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Fort Lauderdale",
        "country": "USA",
        "centiMillionaires": 25,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Ho Chi Minh City",
        "country": "Vietnam",
        "centiMillionaires": 24,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Calgary",
        "country": "Canada",
        "centiMillionaires": 22,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Buenos Aires",
        "country": "Argentina",
        "centiMillionaires": 20,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Salt Lake City",
        "country": "USA",
        "centiMillionaires": 20,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Limassol",
        "country": "Cyprus",
        "centiMillionaires": 20,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Santa Fe",
        "country": "USA",
        "centiMillionaires": 18,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Netanya",
        "country": "Israel",
        "centiMillionaires": 17,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Zagreb",
        "country": "Croatia",
        "centiMillionaires": 15,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Baku",
        "country": "Azerbaijan",
        "centiMillionaires": 15,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Lagos",
        "country": "Nigeria",
        "centiMillionaires": 12,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Marrakech",
        "country": "Morocco",
        "centiMillionaires": 12,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Sharjah",
        "country": "UAE",
        "centiMillionaires": 11,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Durban, Umhlanga, and Ballito",
        "country": "South Africa",
        "centiMillionaires": 11,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Nairobi",
        "country": "Kenya",
        "centiMillionaires": 10,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Casablanca",
        "country": "Morocco",
        "centiMillionaires": 10,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    }
];

export {dataCitiesToWatch};