import {countryCode} from "../data/data-country-code";


export const dataConvertOverview = (jsonOverview) =>{
    // console.log(newObj);
    return jsonOverview.map((item) => ({
        ...item,
        ISO3: countryCode[item.country] ? countryCode[item.country].ISO3 : 'HKG',
        ISO2: countryCode[item.country] ? countryCode[item.country].ISO2 : 'HK',
    }));
}
  