const seasonalCitiesOverview = [
    {
        "city": "Miami",
        "country": "USA",
        "secondhome": " 650",
        "residents": 164,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "The Hamptons",
        "country": "USA",
        "secondhome": " 600",
        "residents": 25,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Liguria (region)",
        "country": "Italy",
        "secondhome": " 550",
        "residents": 84,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Nice to Eze",
        "country": "France",
        "secondhome": " 500",
        "residents": 95,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Palm Beach and West Palm Beach",
        "country": "USA",
        "secondhome": " 400",
        "residents": 69,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Costa del Sol (region)",
        "country": "Spain",
        "secondhome": " 300",
        "residents": 60,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "The Algarve (region)",
        "country": "Portugal",
        "secondhome": " 250",
        "residents": 48,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Cannes",
        "country": "France",
        "secondhome": " 200",
        "residents": 28,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Mykonos (island)",
        "country": "Greece",
        "secondhome": " 200",
        "residents": 22,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Jackson Hole",
        "country": "USA",
        "secondhome": " 200",
        "residents": 15,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Park City",
        "country": "USA",
        "secondhome": " 200",
        "residents": 12,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Aspen",
        "country": "USA",
        "secondhome": " 200",
        "residents": 10,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Cape Town",
        "country": "South Africa",
        "secondhome": " 150",
        "residents": 28,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Boca Raton",
        "country": "USA",
        "secondhome": " 150",
        "residents": 30,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Naples",
        "country": "USA",
        "secondhome": " 150",
        "residents": 27,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Lake Como (region)",
        "country": "Italy",
        "secondhome": " 100",
        "residents": 20,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Rio",
        "country": "Brazil",
        "secondhome": " 100",
        "residents": 36,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Fort Lauderdale",
        "country": "USA",
        "secondhome": " 100",
        "residents": 25,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Pebble Beach and Carmel-by-the-Sea",
        "country": "USA",
        "secondhome": " 100",
        "residents": 30,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Crete (island)",
        "country": "Greece",
        "secondhome": " 100",
        "residents": 20,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Marrakech",
        "country": "Morocco",
        "secondhome": " 100",
        "residents": 12,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Bodrum",
        "country": "Türkiye",
        "secondhome": " 100",
        "residents": 10,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    }
];


export {seasonalCitiesOverview};