import React, { useState, Fragment } from 'react';
import styles from './expensive-cities.module.scss';
import { dataConvertOverview } from '../../utils/overall-convert';
import { expensiveCitiesOverview } from "../../data/data-expensive-cities";
import { formatNumberWithDot } from '../../utils/utils';
import CountryFlag from '../common/countryFlag/country-flag';
import IconSort from '../common/icon/icon-sort';
import ReverseIconSort from '../common/icon/reverse-icon-sort';
import TableNotes from "../common/tableLegend/table-notes";

// const options = [
//   { label: "USD/m²", value: "price" }
// ];

const ExpensiveCities = () => {
  const [sortColumn, setSortColumn] = useState("price");
  // const [display, setDisplay] = useState();
  const [countryCount, setCountryCount] = useState(1);
  const [priceCount, setPriceCount] = useState(1);

  // const handleOptionSelect = (option) => {
  //   // setSelectedOption(option);
  //   setSortColumn(option.value);
  //   setDisplay(-1);
  // };

  // const missingCountry = expensiveCitiesOverview.map((item) => (
  //   item.country, 
  //   countryCode[item.country]
  // ));
  // console.log(missingCountry)

  const dataExpensiveOverview = () => {
    return dataConvertOverview(expensiveCitiesOverview);
  }

  const handleSort = (columnName) => {
    switch (columnName) {
      case 'country':
        (countryCount === 1) ? setCountryCount(-1) : setCountryCount(1);
        break;
      case 'price':
        (priceCount === 1) ? setPriceCount(-1) : setPriceCount(1);
        break;
      default:
        break;
    }

    setSortColumn(columnName);
    // setDisplay(-1);
  };

  const reverseSortNum = (columnName, count) => {
    if (count === 1) {
      return dataExpensiveOverview().sort((a, b) => b[columnName] - a[columnName]);
    }
    return dataExpensiveOverview().sort((a, b) => a[columnName] - b[columnName]);
  }


  const handleSortingOptions = (columnName) => {
    switch (columnName) {
      case 'country':
        return (countryCount === 1) ?
          dataExpensiveOverview().sort((a, b) => b[columnName].localeCompare(a[columnName]))
          : dataExpensiveOverview().sort((a, b) => a[columnName].localeCompare(b[columnName]));
      case 'price':
        return reverseSortNum(columnName, priceCount);
      default:
        break;
    }

  }

  const sortedData = sortColumn
    ? handleSortingOptions(sortColumn)
    : dataExpensiveOverview();


  return (
    <>
      <table className={`chartTable ${styles.overviewChartTable}`}>
        <thead>
          {/* <tr>
            <th className={`text-left`} style={{ 'border': 'None' }}>
              <span>The most expensive cities for centi-millionaires</span>
            </th>
            <td className={styles.cellHideMobile} style={{ 'border': 'None' }}></td>
            <td className={styles.cellHideMobile} style={{ 'border': 'None' }}></td>
          </tr> */}

          <tr>
            <th className={styles.cityColumn} width="38%"><span className={styles.columnText}>City<span
                className={styles.showTextOnMobile}> / County</span></span></th>
            <th
              className={`text-left ${styles.cellHideMobile}  ${styles.sortableColumn} ${sortColumn === 'country' ? styles.selectedColumn : ''}`}
              width="35%"
              onClick={() => handleSort("country")}
            >
              <div className={`${styles.sortColumnTitle} ${styles.reverseSortColumnTitle}`}>
                <span className={styles.sortColumnTitleText}>Country</span>
                {(countryCount === 1) ? <IconSort /> : <ReverseIconSort />}
              </div>
            </th>

            <th
              className={
                `text-right 
                              ${styles.sortableColumn} 
                              ${sortColumn === 'price' ? styles.selectedColumn : ''}
                              ${sortColumn === 'price' ? styles.displayColumnTitleDiv : ''}
              `}
              onClick={() => handleSort("price")}
            >
              <div className={styles.sortColumnTitle}>
                <span className={styles.sortColumnTitleText}>USD/m²</span>
                {(priceCount === 1) ? <IconSort /> : <ReverseIconSort />}
              </div>
            </th>

          </tr>
        </thead>
        <tbody>
          {sortedData.map((item, index) => (
            <Fragment key={index}>
              <tr className={`${styles.contentRow} ${styles.openedRow}`}>
                {/* <tr onClick={() => handleMobileRowClick(index)} className={`${styles.contentRow} ${index === display ? styles.openedRow : ''}`}> */}
                <td>{item.city}</td>
                <td className={`text-left ${styles.cellHideMobile} ${styles.sortableColumn} ${sortColumn === 'country' ? styles.selectedColumn : ''}`}>
                  <CountryFlag country={item.country} />
                </td>

                <td rowSpan={2} className={`text-right ${styles.hiddenTableRow} ${styles.sortableColumn} ${sortColumn ? styles.selectedColumn : ''} ${styles.combinateRowCells} `}>
                  {formatNumberWithDot(item[sortColumn])}
                </td>
                
                <td className={`text-right ${styles.cellHideMobile} ${styles.sortableColumn} ${styles.cellOnMobile} ${sortColumn === 'price' ? styles.selectedColumn : ''}`}>
                  {formatNumberWithDot(item.price)}
                </td>
                

              </tr>
              {/* {index === display && ( */}
              <tr className={`${styles.hiddenTableRow}`}>
                <td className={`${styles.countryMobileDiv}`}>
                  <CountryFlag country={item.country} />
                </td>
                {/* <td className={`text-right ${styles.sortableColumn} ${styles.selectedColumn} ${styles.combinateRowCells}`}></td> */}
              </tr>
              {/* )} */}
            </Fragment>
          ))}

        </tbody>
      </table>

      <TableNotes>
        <ul>
          <li>Refers to the average m² price of a prime 200 to 400 m² apartment in city/town.</li>
          <li>'Prime' refers to premium residential units in affluent parts of each city/town.</li>
          <li>Figures rounded to nearest USD 100 as at June 2024.</li>
        </ul>
      </TableNotes>


    </>
  )
}

export default ExpensiveCities;