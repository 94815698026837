import React from 'react';
import styles from  './progress-icon.module.scss'; // import CSS file with animation styles

const  ProgressIcon= ({count}) => {

    const className = styles[`iconCount${count}`];

    return (
        <div className={`${styles.iconContainer} ${className}`}>
            {Array.from({ length: count }, (_, index) => (
                <span key={index}>
                    <svg width="12" height="22" viewBox="0 0 12 22" fill="none">
                        <path
                            d="M9.31418 15.2495C9.31418 14.5393 9.06015 13.9411 8.55209 13.4547C8.04404 12.9684 7.18743 12.5284 5.98228 12.1347C4.30451 11.633 3.08163 10.9788 2.31364 10.1721C1.54565 9.36544 1.16165 8.3407 1.16165 7.09789C1.16165 5.83193 1.53777 4.79368 2.29001 3.98316C3.04225 3.17263 4.07608 2.69018 5.39151 2.53579V0H7.15199V2.54737C8.4753 2.72491 9.50519 3.26912 10.2417 4.18C10.9782 5.09088 11.3464 6.33754 11.3464 7.92H9.1724C9.1724 6.83158 8.90852 5.96702 8.38077 5.32632C7.85303 4.68561 7.14017 4.36526 6.24221 4.36526C5.30487 4.36526 4.58808 4.60263 4.09184 5.07737C3.5956 5.5521 3.34748 6.21404 3.34748 7.06316C3.34748 7.85053 3.60938 8.47772 4.13319 8.94474C4.657 9.41175 5.51951 9.83632 6.72073 10.2184C7.92195 10.6005 8.85929 11.0212 9.53276 11.4805C10.2062 11.9398 10.7025 12.4744 11.0215 13.0842C11.3405 13.694 11.5 14.4081 11.5 15.2263C11.5 16.5309 11.1003 17.5807 10.3008 18.3758C9.50125 19.1709 8.38077 19.6418 6.93931 19.7884V22H5.19066V19.7884C3.72556 19.6572 2.57752 19.1458 1.74651 18.2542C0.915503 17.3626 0.5 16.1526 0.5 14.6242H2.68582C2.68582 15.6972 2.97333 16.5232 3.54834 17.1021C4.12334 17.6811 4.9386 17.9705 5.99409 17.9705C7.02596 17.9705 7.83727 17.7274 8.42803 17.2411C9.0188 16.7547 9.31418 16.0909 9.31418 15.2495Z"
                            fill="white"/>
                    </svg>
                </span>
            ))}
        </div>
    );
}

export default ProgressIcon;
