import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import CentiMillionaires from './components/centi-millionaires/centi-millionaires';
import CitiesToWatch from "./components/cities-to-watch/cities-to-watch";
import SeasonalCities from './components/seasonal-cities/seasonal-cities';
import ExpensiveCities from './components/expensive-cities/expensive-cities';
import './App.scss';
import qs from "qs";

function getEl(embedSelector) {
    if (typeof embedSelector === "string") {
        const el = document.querySelector(embedSelector);
        // if (!el) throw new Error(`No div matching selector "${embedSelector}"`);
        if (!el) return;
        return el;
    } else {
        return embedSelector;
    }
}

// function getEls(embedSelector) {
//     if (typeof embedSelector === "string") {
//         const els = document.querySelectorAll(embedSelector);
//         // if (!els) throw new Error(`No divs matching selector "${embedSelector}"`);
//         if (!els) return;
//         return els;
//     } else {
//         return embedSelector;
//     }
// }

function getUrlParams() {
    const params = qs.parse(window.location.search.slice(1));

    return {
        type: params.embed,
        city: params.city,
    };
}

window.addEventListener("DOMContentLoaded", () => {
    const params = getUrlParams();
    
    if (params) {
        
        if (getEl("[data-top50-cities-embed]")) {
          const container = getEl("[data-top50-cities-embed]");
          const root = createRoot(container); // createRoot(container!) if you use TypeScript
          root.render(<CentiMillionaires {...params} />);
        }


        if (getEl("[data-cities-to-watch-embed]")) {
            const container = getEl("[data-cities-to-watch-embed]");
            const root = createRoot(container); // createRoot(container!) if you use TypeScript
            root.render(<CitiesToWatch {...params} />);
        }

        if (getEl("[data-seasonal-cities-embed]")) {
          const container = getEl("[data-seasonal-cities-embed]");
          const root = createRoot(container); // createRoot(container!) if you use TypeScript
          root.render(<SeasonalCities {...params} />);
        }
        if (getEl("[data-expensive-cities-embed]")) {
          const container = getEl("[data-expensive-cities-embed]");
          const root = createRoot(container); // createRoot(container!) if you use TypeScript
          // ReactDOM.render(<App {...params} />, getEl("[data-app-embed]"));
          root.render(<ExpensiveCities {...params} />);
        }


    }
});


