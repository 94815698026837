const centiOverview = [
    {
        "city": "New York City",
        "country": "USA",
        "centiMillionaires": 744,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "The Bay Area",
        "country": "USA",
        "centiMillionaires": 675,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Los Angeles",
        "country": "USA",
        "centiMillionaires": 496,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "London",
        "country": "UK",
        "centiMillionaires": 370,
        "centiGrowthText": "LOW",
        "centiGrowth": 1
    },
    {
        "city": "Beijing",
        "country": "China",
        "centiMillionaires": 347,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Singapore",
        "country": "Singapore",
        "centiMillionaires": 336,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Shanghai",
        "country": "China",
        "centiMillionaires": 322,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Hong Kong",
        "country": "Hong Kong (SAR China)",
        "centiMillionaires": 320,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Chicago",
        "country": "USA",
        "centiMillionaires": 290,
        "centiGrowthText": "LOW",
        "centiGrowth": 1
    },
    {
        "city": "Paris",
        "country": "France",
        "centiMillionaires": 286,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Tokyo",
        "country": "Japan",
        "centiMillionaires": 267,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Houston",
        "country": "USA",
        "centiMillionaires": 258,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Geneva (canton)",
        "country": "Switzerland",
        "centiMillionaires": 244,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Mumbai",
        "country": "India",
        "centiMillionaires": 236,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Dubai",
        "country": "UAE",
        "centiMillionaires": 212,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Moscow",
        "country": "Russian Federation",
        "centiMillionaires": 207,
        "centiGrowthText": "LOW",
        "centiGrowth": 1
    },
    {
        "city": "Sydney",
        "country": "Australia",
        "centiMillionaires": 205,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Zurich (canton)",
        "country": "Switzerland",
        "centiMillionaires": 205,
        "centiGrowthText": "LOW",
        "centiGrowth": 1
    },
    {
        "city": "Toronto",
        "country": "Canada",
        "centiMillionaires": 195,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Seoul",
        "country": "South Korea",
        "centiMillionaires": 195,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Monaco",
        "country": "Monaco",
        "centiMillionaires": 185,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Milan",
        "country": "Italy",
        "centiMillionaires": 165,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Miami",
        "country": "USA",
        "centiMillionaires": 164,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Frankfurt",
        "country": "Germany",
        "centiMillionaires": 155,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Shenzhen",
        "country": "China",
        "centiMillionaires": 154,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Seattle",
        "country": "USA",
        "centiMillionaires": 130,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Dallas",
        "country": "USA",
        "centiMillionaires": 125,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Delhi",
        "country": "India",
        "centiMillionaires": 123,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Greenwich and Darien",
        "country": "USA",
        "centiMillionaires": 120,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Melbourne",
        "country": "Australia",
        "centiMillionaires": 112,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Boston",
        "country": "USA",
        "centiMillionaires": 107,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Hangzhou",
        "country": "China",
        "centiMillionaires": 102,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Nice to Èze",
        "country": "France",
        "centiMillionaires": 95,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Rome",
        "country": "Italy",
        "centiMillionaires": 92,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Austin",
        "country": "USA",
        "centiMillionaires": 92,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Amsterdam",
        "country": "Netherlands",
        "centiMillionaires": 91,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Washington, D.C.",
        "country": "USA",
        "centiMillionaires": 88,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Munich",
        "country": "Germany",
        "centiMillionaires": 85,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Taipei",
        "country": "Taiwan (Chinese Taipei)",
        "centiMillionaires": 82,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Tel Aviv",
        "country": "Israel",
        "centiMillionaires": 82,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Luxembourg City",
        "country": "Luxembourg",
        "centiMillionaires": 82,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Vancouver",
        "country": "Canada",
        "centiMillionaires": 80,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "San Diego",
        "country": "USA",
        "centiMillionaires": 75,
        "centiGrowthText": "MODERATE",
        "centiGrowth": 2
    },
    {
        "city": "Madrid",
        "country": "Spain",
        "centiMillionaires": 74,
        "centiGrowthText": "LOW",
        "centiGrowth": 1
    },
    {
        "city": "Vienna",
        "country": "Austria",
        "centiMillionaires": 70,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Guangzhou",
        "country": "China",
        "centiMillionaires": 70,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Las Vegas",
        "country": "USA",
        "centiMillionaires": 70,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Osaka",
        "country": "Japan",
        "centiMillionaires": 69,
        "centiGrowthText": "HIGH",
        "centiGrowth": 3
    },
    {
        "city": "Palm Beach and West Palm Beach",
        "country": "USA",
        "centiMillionaires": 69,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    },
    {
        "city": "Abu Dhabi",
        "country": "UAE",
        "centiMillionaires": 68,
        "centiGrowthText": "VERY HIGH",
        "centiGrowth": 4
    }
];

const centiWorldData = [
  {
      "city": "World",
      "country": "",
      "centiMillionaires": "29350",
      "centiGrowth": "2"
  }
];

export {centiOverview, centiWorldData};